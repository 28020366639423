body {
  margin: 0;
  padding: 0;
  background-color: white;
}

:root {
  --gray-150: #3d4467;
}

.gray-50 {
  color: #c3c5ce;
}

.gray-90 {
  color: #5e6379;
}

.gray-100 {
  color: #545b79;
}

.default-color {
  color: #545b79;
}

.bg-black-100 {
  background-color: #eeeded;
  border: 0;
}

/* Button hover effect */
#back-to-top-btn:hover {
  background-color: #6b8b65; /* Change background color on hover */
}

.title {
  width: fit-content;
  max-width: 710px;
  margin: 0 0 30px;
  font-size: 48px;
  color: white;
}

.w-min-content {
  width: min-content;
}

/* Start swiper style */

.swiper {
  width: 100%;
  height: fit-content;
  padding-bottom: 35px;
}

.swiper-slide {
  font-size: 18px;
  margin-right: 15px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  /* width: 100%; */
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

.swiper-pagination-bullet {
  background-color: #43766c;
}

.swiper-button-next,
.swiper-button-prev {
  color: #43766c;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: 'Barlow', sans-serif;
  font-size: 20px;
  padding: 10px 15px;
  background-color: #ffffff;
  border-radius: 50%;
}

.swiper-button-next:after {
  margin-right: 15px;
}

/* End swiper style */


/* start Loading Style */

.loading::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: -10%;
  width: 150px;
  height: 150px;
  background-color: #356df6;
  border-radius: 50%;
  filter: blur(12rem);
  z-index: -999;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-filter: blur(12rem);
}

.loading div h1 {
  text-align: left;
  font-weight: 900;
  color: #597e52;
}

.loading div h2 {
  text-align: left;
  font-weight: 900;
  color: #e3f7f0;
}

.loading div button {
  font-family: 'Barlow', sans-serif;
  text-align: left;
  font-weight: 900;
  color: white;
  border: 0;
  padding-inline: 30px;
  border-radius: 30px;
  padding-block: 15px;
  box-shadow: 0.5px 5px #fbf6ee;
  text-align: center;
  background-color: #43766c;
}

.loading div button:hover {
  font-family: 'Barlow', sans-serif;
  text-align: left;
  font-weight: 900;
  color: white;
  border: 0;
  padding-inline: 30px;
  border-radius: 30px;
  padding-block: 15px;
  box-shadow: 0.5px 5px #fbf6ee;
  text-align: center;
  background-color: black;
}

.loading .features {
  width: inherit;
}

.loading div p {
  font-weight: 600;
}

.loading div .cards {
  position: relative;
  left: calc(55% - 60px);
  transform: translateX(-50%);
  width: fit-content;
  display: flex;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.imageHeader {
  height: 100%;
  width: 100%;
  /* background-color: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)); */
}

#container-header {
  position: absolute;
  top: 150%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.loading div .cards .card {
  width: fit-content;
}

.loading div .cards .card:first-child {
  left: -25px;
  transform: rotate(-10deg);
  -webkit-transform: rotate(-10deg);
  -moz-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  -o-transform: rotate(-10deg);
}

.loading div .cards .card:last-child {
  position: absolute;
  top: 25px;
  right: -120px;
  box-shadow: -3px -4px 11px 5px #00000059;
  transform: rotate(10deg);
  -webkit-transform: rotate(10deg);
  -moz-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  -o-transform: rotate(10deg);
}

.loading div .cards .card .card-body img {
  max-width: 190px;
}

/* End loading style */

/* Start partners style */

.partners {
  background-color: #fafafa;
}

.partners div h1 {
  text-align: left;
  color: #597e52;
  margin-bottom: 2rem;
  font-weight: 900;
  font-size: 40px;
}

.partners div p {
  font-family: 'Barlow', sans-serif;
  text-align: left;
  color: #00000066;
  margin-bottom: 1rem;
  font-style: italic;
  font-weight: 500;
}

.partners div h6 {
  font-family: 'Barlow', sans-serif;
  text-align: left;
  color: #00000066;
  margin-bottom: 5rem;
  font-weight: 300;
}

.partners div h3 {
  font-family: 'Barlow', sans-serif;
  text-align: left;
  color: black;
  font-weight: 900;
}
/* End partners style */

/* Start properties style */
.properties {
  padding-block: 5rem;
}

.properties div h1 {
  text-align: left;
  color: #597e52;
  margin-bottom: 1rem;
  font-weight: 900;
  font-size: 40px;
}

.properties div p {
  text-align: center;
  margin-bottom: 3rem;
  color: #00000066;
}

.properties div h5 {
  font-family: 'Barlow', sans-serif;
  color: #597e52;
  font-weight: 900;
}

.properties div h4 {
  color: #597e52;
  font-weight: 700;
}

.properties div h6 {
  font-family: 'Barlow', sans-serif;
  color: #00000066;
  font-weight: 500;
}
.properties div span {
  color: #597e52;
}

.like {
  position: absolute;
  color: white;
  background-color: rgb(18 20 32 / 75%);
  padding: 10px;
  border-radius: 50%;
  top: 10px;
  right: 10px;
}

.card .card-body div img {
  max-width: 256px;
}

.card .card-body div div h6 {
  font-family: 'Barlow', sans-serif;
  font-size: 0.9rem;
}

.card .card-body div div span {
  font-size: 14px;
}

.card .card-body p {
  font-family: 'Barlow', sans-serif;
  font-size: 14px;
}

/* End properties style */

/* Start about us */
.about {
  padding-block: 50px;
  background-color: #fafafa;
  margin-top: 50px;
  text-align: center;
}

.about div p {
  font-family: 'Barlow', sans-serif;
  text-align: right;
  color: #00000066;
}

.about div p a {
  color: yellow;
}

.about div h1 {
  text-align: left;
  color: #597e52;
  margin-bottom: 2rem;
  font-weight: 900;
  font-size: 40px;
}

.about div h5 {
  font-family: 'Barlow', sans-serif;
  margin-bottom: 10px;
  font-weight: bold;
  color: black;
}

.about div h6 {
  font-family: 'Barlow', sans-serif;
  margin-bottom: 10px;
  font-weight: 300;
  color: #00000066;
}

/* End about us */

/* Start developers */

.developers {
  background-color: #fafafa;
  padding-block: 50px;
}

.developers div h1 {
  text-align: left;
  color: #597e52;
  margin-bottom: 3rem;
  font-weight: 900;
  font-size: 40px;
}

.developers div p {
  color: #00000066;
}

.img-promo {
  width: 50%;
}
/* End developers */

/* start hubungi */

.hubungi {
  padding-block: 1rem;
  margin-top: 5rem;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../src/images/headers/sapi-header4.jpg");
  color: black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.hubungi div h3 {
  font-family: 'Barlow', sans-serif;
  text-align: left;
  color: white;
  font-weight: 900;
  font-size: 40px;
}

.testContact {
  border-radius: 5px;
  padding-block: 1rem;
  padding-inline: 3rem;
  letter-spacing: 1px;
  margin-bottom: 2rem;
  background-color: #3e755f;
}

/* .center-div {
  width: 40%;
} */

.contact {
  background-color: #12372a;
  margin-top: -3rem;
  margin-bottom: -1rem;
  padding-block: 2rem;
  padding-inline: 2rem;
  height: auto;
  border-radius: 5px;
}

.buttonCon {
  font-family: 'Barlow', sans-serif;
  text-align: left;
  font-weight: 900;
  color: white;
  border: 0;
  padding-inline: 30px;
  border-radius: 30px;
  padding-block: 15px;
  box-shadow: 0.5px 5px #fbf6ee;
  text-align: center;
  background-color: #43766c;
}

.hubungi div p {
  color: white;
}
/* End hubungi */

/* Start join */

.join {
  margin-top: 50px;
  text-align: center;
}

.join div p {
  color: #00000066;
}

.join div h1 {
  text-align: left;
  color: #597e52;
  margin-bottom: 1rem;
  font-weight: 900;
  font-size: 40px;
}

.join div h5 {
  font-family: 'Barlow', sans-serif;
  margin-bottom: 10px;
  font-weight: bold;
  color: black;
}

.join div h6 {
  font-family: 'Barlow', sans-serif;
  margin-bottom: 10px;
  font-weight: 300;
  color: #00000066;
}

/* End join */

/* Start subscribe */

.subscribe {
  margin-top: 50px;
}

.subscribe div p {
  color: #00000066;
}

.subscribe div h1 {
  text-align: left;
  color: #597e52;
  margin-bottom: 2rem;
  font-weight: 900;
  font-size: 40px;
}

.h2 {
  text-align: left;
  font-weight: 900;
  color: #597e52;
}

/* End subscribe */

/* Start faq */

.faq {
  background-color: #fafafa;
  padding-block: 50px;
  padding-block-start: 60px;
}

.faq div p {
  color: #00000066;
}

.faq div h1 {
  text-align: center;
  color: #597e52;
  margin-bottom: 2rem;
  font-weight: 900;
  font-size: 40px;
}

/* End faq */

/* Start footer */

footer {
  padding-top: 20px;
  padding-inline: 15px;
  background-color: #597e52;
  text-align: center;
}

footer div p {
  color: white;
}

footer div h1 {
  font-family: 'Forum', sans-serif;
  text-align: center;
  color: white;
  font-weight: 900;
}

.buttonHub {
  font-family: 'Barlow', sans-serif;
  text-align: left;
  font-weight: 900;
  color: white;
  border: 0;
  padding-inline: 30px;
  border-radius: 30px;
  padding-block: 15px;
  box-shadow: 0.5px 5px #000000;
  text-align: center;
  background-color: #43766c;
}

.line-top {
  border-top: 0.5px solid white;
}

footer div p {
  font-size: 14px;
}

/* End footer */

/* Start media query */

/* Small */
@media (max-width: 768px) {
  .contact {
    width: 100%;
    margin-bottom: 2rem;
  }
  .imageHeader {
    object-fit: cover;
    height: 768px;
  }
  .loading .img-logo {
    margin-bottom: 45%;
  }
  #container-header {
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
}

/* medium */

@media (min-width: 768px) {
  .contact {
    width: 100%;
    margin-bottom: 2rem;
  }
  .imageHeader {
    object-fit: cover;
    height: 768px;
  }
  .loading .img-logo {
    margin-bottom: 30%;
  }
  #container-header {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
}

@media (max-width: 992px) {
  .contact {
    width: 100%;
    margin-bottom: 2rem;
  }
  .imageHeader {
    object-fit: cover;
    height: 750px;
  }
  #container-header {
    position: absolute;
    top: 45%;
    left: 50%;
    color: #fff;
  }
}

/* Large */

@media (min-width: 1198px) {
  .contact {
    width: 90%;
  }
  .imageHeader {
    object-fit: cover;
    height: 910px;
  }
  .loading .img-logo {
    margin-bottom: 35%;
  }
  #container-header {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
}

/* End media query */
